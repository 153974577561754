<template>
  <div v-if="$wait.is(loadingEvent)" class="w-screen h-screen fixed top-0 left-0 bg-pumpkin_light_15 flex justify-center items-center loading-screen z-50">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
export default {
  name:"LoadingScreen",
  props: ["loadingObject"],
  data() {
    return {
      loadingEvent: ''
    }
  },
  created() {
    this.loadingEvent = this.$props.loadingObject;
  }
}
</script>

<style scoped lang="scss">
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--green-light);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--green-light) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>